import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  InternalLink,
  Image,
  Background,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"about-page"}>
          <Grid
            stackable={true}
            doubling={true}
            columns={2}
            className={"about-grid"}
          >
            <Grid.Column className={"content-column"}>
              <Content className={"about-content"} textAlign={"left"}>
                <Header as={"h1"} className={"about-us-header"}>
                  GEORGETOWN GHOST STORY
                </Header>
                <Header as={"h3"} className={"about-us-header"}>
                  by Paul Dale Roberts, HPI Ghostwriter
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={false}
                  content={
                    <div>
                      <div>
                        It's 10 a.m. and HPI paranormal investigator, Hanako
                        Zeidenberg is in my driveway. Hanako has driven all the
                        way from Oakland, CA to do a scouting mission to
                        Georgetown. Georgetown is located deep in the valley on
                        Hwy 49 between Placerville, CA and Auburn, CA. Hanako
                        jumps into my vehicle, known as the Ghost Tracker, and
                        we head up towards Hwy 80 to pick up paranormal
                        investigator / sensitive, Holly DeLaughter. After
                        driving down historic Hwy 49 through a zillion winding
                        roads to get to Georgetown, we stop first at the
                        Georgetown Hotel. We meet up with HPI paranormal
                        investigator / researcher Michele Stump and her
                        boyfriend Scott. I introduce myself to the bartender of
                        the Georgetown Hotel, and tell her I am a paranormal
                        journalist for HPI and I am here to do a story on their
                        haunted hotel. She is full of ghost stories and is eager
                        to share them with me.
                      </div>
                      <Button
                        primary={true}
                        size={"large"}
                        to={"/georgetown-ghost-story/"}
                        as={InternalLink}
                      >
                        Read More
                      </Button>
                    </div>
                  }
                />
              </Content>
            </Grid.Column>
            <Grid.Column className={"image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <PaginatedGrid
                  rows={2}
                  columns={4}
                  className={"image-gallery-grid"}
                  mobile={{ columns: 2, rows: 4, stackable: false }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    }),
                    component: <Image background={true} className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Background.Pattern>
            </Background>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
